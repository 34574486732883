import React from "react";
import Gallery from "react-photo-gallery";

import one0 from "../Images/Event/1 (0).JPG";
import one1 from "../Images/Event/1 (1).JPG";
import one2 from "../Images/Event/1 (2).JPG";
import one3 from "../Images/Event/1 (3).JPG";
import one4 from "../Images/Event/1 (4).JPG";
import one5 from "../Images/Event/1 (5).JPG";
import one6 from "../Images/Event/1 (6).JPG";
import one7 from "../Images/Event/1 (7).JPG";
import one8 from "../Images/Event/1 (8).JPG";
import one9 from "../Images/Event/1 (9).JPG";
import one10 from "../Images/Event/1 (10).JPG";
import one11 from "../Images/Event/1 (11).JPG";
import one12 from "../Images/Event/1 (12).JPG";
import one13 from "../Images/Event/1 (13).JPG";
import one14 from "../Images/Event/1 (14).JPG";
import one15 from "../Images/Event/1 (15).JPG";
import one16 from "../Images/Event/1 (16).JPG";
import one17 from "../Images/Event/1 (17).JPG";
import one18 from "../Images/Event/1 (18).JPG";
import one19 from "../Images/Event/1 (19).JPG";
import one20 from "../Images/Event/1 (20).JPG";
import one21 from "../Images/Event/1 (21).JPG";
import one22 from "../Images/Event/1 (22).JPG";
import one23 from "../Images/Event/1 (23).JPG";
import one24 from "../Images/Event/1 (24).JPG";
import one25 from "../Images/Event/1 (25).JPG";
import one26 from "../Images/Event/1 (26).JPG";
import one27 from "../Images/Event/1 (27).JPG";
import one28 from "../Images/Event/1 (28).JPG";
import one29 from "../Images/Event/1 (29).JPG";
import one30 from "../Images/Event/1 (30).JPG";
import one31 from "../Images/Event/1 (31).JPG";
import one32 from "../Images/Event/1 (32).JPG";
import one33 from "../Images/Event/1 (33).JPG";
import one34 from "../Images/Event/1 (34).JPG";
import one35 from "../Images/Event/1 (35).JPG";
import one36 from "../Images/Event/1 (36).JPG";
import one37 from "../Images/Event/1 (37).JPG";
import one38 from "../Images/Event/1 (38).JPG";
import one39 from "../Images/Event/1 (39).JPG";

import two0 from "../Images/Event/2 (0).JPG";
import two1 from "../Images/Event/2 (1).JPG";
import two2 from "../Images/Event/2 (2).JPG";
import two3 from "../Images/Event/2 (3).JPG";
import two4 from "../Images/Event/2 (4).JPG";
import two5 from "../Images/Event/2 (5).JPG";
import two6 from "../Images/Event/2 (6).JPG";
import two7 from "../Images/Event/2 (7).JPG";
import two8 from "../Images/Event/2 (8).JPG";
import two9 from "../Images/Event/2 (9).JPG";
import two10 from "../Images/Event/2 (10).JPG";
import two11 from "../Images/Event/2 (11).JPG";
import two12 from "../Images/Event/2 (12).JPG";
import two13 from "../Images/Event/2 (13).JPG";
import two14 from "../Images/Event/2 (14).JPG";
import two15 from "../Images/Event/2 (15).JPG";
import two16 from "../Images/Event/2 (16).JPG";
import two17 from "../Images/Event/2 (17).JPG";
import two18 from "../Images/Event/2 (18).JPG";
import two19 from "../Images/Event/2 (19).JPG";
import two20 from "../Images/Event/2 (20).JPG";
import two21 from "../Images/Event/2 (21).JPG";
import two22 from "../Images/Event/2 (22).JPG";
import two23 from "../Images/Event/2 (23).JPG";
import two24 from "../Images/Event/2 (24).JPG";
import two25 from "../Images/Event/2 (25).JPG";
import two26 from "../Images/Event/2 (26).JPG";
import two27 from "../Images/Event/2 (27).JPG";
import two28 from "../Images/Event/2 (28).JPG";
import two29 from "../Images/Event/2 (29).JPG";
import two30 from "../Images/Event/2 (30).JPG";
import two31 from "../Images/Event/2 (31).JPG";
import two32 from "../Images/Event/2 (32).JPG";
import two33 from "../Images/Event/2 (33).JPG";
import two34 from "../Images/Event/2 (34).JPG";
import two35 from "../Images/Event/2 (35).JPG";
import two36 from "../Images/Event/2 (36).JPG";
import two37 from "../Images/Event/2 (37).JPG";
import two38 from "../Images/Event/2 (38).JPG";

import three0 from "../Images/Event/3 (0).jpeg";
import three1 from "../Images/Event/3 (1).jpeg";
import three2 from "../Images/Event/3 (2).jpeg";
import three3 from "../Images/Event/3 (3).jpeg";
import three4 from "../Images/Event/3 (4).jpeg";
import three5 from "../Images/Event/3 (5).jpeg";
import three6 from "../Images/Event/3 (6).jpeg";
import three7 from "../Images/Event/3 (7).jpeg";
import three8 from "../Images/Event/3 (8).jpeg";
import three9 from "../Images/Event/3 (9).jpeg";
import three10 from "../Images/Event/3 (10).jpeg";
import three11 from "../Images/Event/3 (11).jpeg";
import three12 from "../Images/Event/3 (12).jpeg";
import three13 from "../Images/Event/3 (13).jpeg";
import three14 from "../Images/Event/3 (14).jpeg";

const SeminarOne = [
  {
    src: one0,
    width: 2,
    height: 1,
  },
  {
    src: one1,
    width: 2,
    height: 1,
  },
  {
    src: one2,
    width: 2,
    height: 1,
  },
  {
    src: one3,
    width: 2,
    height: 1,
  },
  {
    src: one4,
    width: 2,
    height: 1,
  },
  {
    src: one5,
    width: 2,
    height: 1,
  },
  {
    src: one6,
    width: 2,
    height: 1,
  },
  {
    src: one7,
    width: 2,
    height: 1,
  },
  {
    src: one8,
    width: 2,
    height: 1,
  },
  {
    src: one9,
    width: 2,
    height: 1,
  },
  {
    src: one10,
    width: 2,
    height: 1,
  },
  {
    src: one11,
    width: 2,
    height: 1,
  },
  {
    src: one12,
    width: 2,
    height: 1,
  },
  {
    src: one13,
    width: 2,
    height: 1,
  },
  {
    src: one14,
    width: 2,
    height: 1,
  },
  {
    src: one15,
    width: 2,
    height: 1,
  },
  {
    src: one16,
    width: 2,
    height: 1,
  },
  {
    src: one17,
    width: 2,
    height: 1,
  },
  {
    src: one18,
    width: 2,
    height: 1,
  },
  {
    src: one19,
    width: 2,
    height: 1,
  },
  {
    src: one28,
    width: 2,
    height: 2,
  },
  {
    src: one21,
    width: 2,
    height: 2,
  },
  {
    src: one22,
    width: 2,
    height: 1,
  },
  {
    src: one23,
    width: 2,
    height: 1,
  },
  {
    src: one24,
    width: 2,
    height: 1,
  },
  {
    src: one25,
    width: 2,
    height: 1,
  },
  {
    src: one26,
    width: 2,
    height: 1,
  },
  {
    src: one27,
    width: 2,
    height: 1,
  },
  {
    src: one20,
    width: 2,
    height: 1,
  },
  {
    src: one29,
    width: 2,
    height: 1,
  },
  {
    src: one30,
    width: 2,
    height: 1,
  },
  {
    src: one31,
    width: 2,
    height: 1,
  },
  {
    src: one32,
    width: 2,
    height: 1,
  },
  {
    src: one33,
    width: 2,
    height: 1,
  },
  {
    src: one34,
    width: 2,
    height: 1,
  },
  {
    src: one35,
    width: 2,
    height: 1,
  },
  {
    src: one36,
    width: 2,
    height: 1,
  },
  {
    src: one37,
    width: 2,
    height: 1,
  },
  {
    src: one38,
    width: 2,
    height: 1,
  },
  {
    src: one39,
    width: 2,
    height: 1,
  },
];

const SeminarTwo = [
  {
    src: two0,
    width: 2,
    height: 1,
  },
  {
    src: two1,
    width: 2,
    height: 1,
  },
  {
    src: two2,
    width: 2,
    height: 1,
  },
  {
    src: two3,
    width: 2,
    height: 1,
  },
  {
    src: two4,
    width: 2,
    height: 1,
  },
  {
    src: two5,
    width: 2,
    height: 2,
  },
  {
    src: two6,
    width: 2,
    height: 1,
  },
  {
    src: two7,
    width: 2,
    height: 1,
  },
  {
    src: two8,
    width: 2,
    height: 1,
  },
  {
    src: two9,
    width: 2,
    height: 1,
  },
  {
    src: two10,
    width: 2,
    height: 1,
  },
  {
    src: two11,
    width: 2,
    height: 1,
  },
  {
    src: two12,
    width: 2,
    height: 1,
  },
  {
    src: two13,
    width: 2,
    height: 1,
  },
  {
    src: two14,
    width: 2,
    height: 1,
  },
  {
    src: two15,
    width: 2,
    height: 1,
  },
  {
    src: two16,
    width: 2,
    height: 1,
  },
  {
    src: two17,
    width: 2,
    height: 1,
  },
  {
    src: two18,
    width: 2,
    height: 1,
  },
  {
    src: two19,
    width: 2,
    height: 1,
  },
  {
    src: two20,
    width: 2,
    height: 1,
  },
  {
    src: two21,
    width: 2,
    height: 1,
  },
  {
    src: two22,
    width: 2,
    height: 1,
  },
  {
    src: two23,
    width: 2,
    height: 1,
  },
  {
    src: two24,
    width: 2,
    height: 1,
  },
  {
    src: two25,
    width: 2,
    height: 1,
  },
  {
    src: two26,
    width: 2,
    height: 1,
  },
  {
    src: two27,
    width: 2,
    height: 1,
  },
  {
    src: two28,
    width: 2,
    height: 1,
  },
  {
    src: two29,
    width: 2,
    height: 1,
  },
  {
    src: two30,
    width: 2,
    height: 1,
  },
  {
    src: two31,
    width: 2,
    height: 1,
  },
  {
    src: two32,
    width: 2,
    height: 1,
  },
  {
    src: two33,
    width: 2,
    height: 1,
  },
  {
    src: two34,
    width: 2,
    height: 1,
  },
  {
    src: two35,
    width: 2,
    height: 1,
  },
  {
    src: two36,
    width: 2,
    height: 1,
  },
  {
    src: two37,
    width: 2,
    height: 1,
  },
  {
    src: two38,
    width: 2,
    height: 1,
  },
];

const seminarThree = [
  {
    src: three0,
    width: 2,
    height: 1,
  },
  {
    src: three1,
    width: 2,
    height: 1,
  },
  {
    src: three2,
    width: 2,
    height: 1,
  },
  {
    src: three3,
    width: 2,
    height: 1,
  },
  {
    src: three4,
    width: 2,
    height: 1,
  },
  {
    src: three5,
    width: 2,
    height: 1,
  },
  {
    src: three6,
    width: 2,
    height: 1,
  },
  {
    src: three7,
    width: 2,
    height: 1,
  },
  {
    src: three8,
    width: 2,
    height: 1,
  },
  {
    src: three9,
    width: 2,
    height: 1,
  },
  {
    src: three10,
    width: 2,
    height: 1,
  },
  {
    src: three11,
    width: 2,
    height: 1,
  },
  {
    src: three12,
    width: 2,
    height: 1,
  },
  {
    src: three13,
    width: 2,
    height: 1,
  },
  {
    src: three14,
    width: 2,
    height: 1,
  },
];

const Events = () => {
  return (
    <div className="bg-[#f7f7f799] rounded-3xl lg:px-16 2xl:pt-0 sm:px-10 px-6 2xl:pb-16 lg:pt-5 lg:pb-14 pt-14 pb-7 shadow-2xl lg:mx-20 sm:mx-10 mx-5 lg:mt-20 mt-10 w-[unset]">
      <p className="2xl:pb-2 md:pb-0.5 pb-0 text-secondary text-center sm:text-lg xl:text-xl 2xl:text-3xl lg:text-2xl md:text-xl text-lg font-bold text_raleway">
        Photo Album
      </p>
      <h1 className="2xl:text-6xl lg:text-5xl font-bold text-primary text-center text-3xl sm:text-4xl xl:text-4xl text_raleway">
        Events
      </h1>
      <h5 className="text_raleway text-center text-primary xl:text-2xl lg:text-xl sm:text-sm text-xs font-bold pt-10 pb-4">
        Seminar 3, 2023
      </h5>
      <Gallery photos={seminarThree} />
      <h5 className="text_raleway text-center text-primary xl:text-2xl lg:text-xl sm:text-sm text-xs font-bold pt-10 pb-4">
        Seminar 2, 2018
      </h5>
      <Gallery photos={SeminarTwo} />
      <h5 className="text_raleway text-center text-primary xl:text-2xl lg:text-xl sm:text-sm text-xs font-bold pt-10 pb-4">
        Seminar 1, 2016
      </h5>
      <Gallery photos={SeminarOne} />
    </div>
  );
};

export default Events;
